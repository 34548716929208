import makeApiCall from "../master/axiosIndex";

const invoiceApis = {
  submitInvoice: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/submitInvoice",
      method: "post",
      data: data,
    }),
  fetchAllInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllInvoices",
      method: "get",
    }),
  fetchAllInvoicesByFlag: ({
    eInvoiceStatus,
    eInvoiceCancelStatus,
    ewbStatus,
    ewbCancelStatus,
    ewbExpiry,
  }: any): Promise<any> => {
    const queryParams = new URLSearchParams();
    if (eInvoiceStatus) queryParams.append("eInvoiceStatus", "Y");
    if (eInvoiceCancelStatus) queryParams.append("eInvoiceCancelStatus", "Y");
    if (ewbStatus) {
      queryParams.append("ewbStatus", "Y");
    } else {
      queryParams.append("ewbStatus", "N");
    }
    if (ewbCancelStatus) queryParams.append("ewbCancelStatus", "Y");
    if (ewbExpiry) queryParams.append("ewbExpiry", "Y");

    const url = `/billing/fetchAllInvoices?${queryParams.toString()}`;
    return makeApiCall<any>({
      url,
      method: "get",
    });
  },
  fetchInvoice: (id: string): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchInvoice?invoiceNumber=${id}`,
      method: "get",
    }),
  fetchAllUserPendingApprovalInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllUserPendingApprovalInvoices",
      method: "get",
    }),
  amendmentInvoice: (invoiceNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/amendmentInvoice/${invoiceNumber}`,
      method: "put",
      data: data,
    }),
  approveInvoice: (invoiceNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/approveInvoice?invoiceNumber=${invoiceNumber}`,
      method: "patch",
    }),

  disapproveInvoice: (
    invoiceNumber: string,
    rejectedReason: string
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/disapproveInvoice?invoiceNumber=${invoiceNumber}&rejectedReason=${rejectedReason}`,
      method: "patch",
    }),
  fetchAllInvoicesByOrderAndOrderLineNo: (
    orderId: any,
    orderLineId: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesByOrderAndOrderLineNo?orderNumber=${orderId}&orderLineNo=${orderLineId}`,
      method: "get",
    }),
  fetchAllSupplierInvoicesForDispatch: (): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllSupplierInvoicesForDispatch`,
      method: "get",
    }),
  fetchAllPaidInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllPaidInvoices",
      method: "get",
    }),
  fetchAllInvoicesForCreditDebitNote: (orgOrSupplierId: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesForCreditDebitNote?orgOrSupplierId=${orgOrSupplierId}`,
      method: "get",
    }),
  eInvoiceAuthentication: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/eInvoiceAuthentication",
      method: "post",
      data: data,
    }),
  eWayBillAuthentication: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/ewbAuthentication",
      method: "post",
      data: data,
    }),
  eInvoiceGeneration: (
    data: any,
    eInvoiceAuthentication: any
  ): Promise<any> => {
    return makeApiCall<any>({
      url: "/billing/eInvoiceGeneration",
      method: "post",
      data: data,
      headers: {
        eInvoiceAuthentication: eInvoiceAuthentication,
      },
    });
  },
  eWayBillGenerationByIRN: (
    data: any,
    eInvoiceAuthentication: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/eWayBillGenerationByIRN",
      method: "post",
      data: data,
      headers: {
        eInvoiceAuthentication: eInvoiceAuthentication,
      },
    }),
  eInvoiceCancellation: (
    data: any,
    eInvoiceAuthentication: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/eInvoiceCancellation",
      method: "patch",
      data: data,
      headers: {
        eInvoiceAuthentication: eInvoiceAuthentication,
      },
    }),
  eWayBillCancellation: (
    data: any,
    eInvoiceAuthentication: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/eWayBillCancellation",
      method: "patch",
      data: data,
      headers: {
        eInvoiceAuthentication: eInvoiceAuthentication,
      },
    }),
  sendInvoiceEmailNotification: (invoiceNumber: any, data: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/sendInvoiceEmailNotification/${invoiceNumber}`,
      method: "post",
      data: data,
    }),
};

export default invoiceApis;
