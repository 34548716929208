import React, { useState, useEffect, useRef } from 'react';
import { Empty } from 'antd';
import * as echarts from 'echarts';

import { formatCurrency2, formatCurrency } from '../../utils/Utils';
interface QuotationsAwardsData {
    month: string;
    year: string;
    quotations: number;
    awards: number;
    invoiced: number; // Add invoiced field
}

const QuotationsVsAwardsChart: React.FC<any> = ({data}) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<echarts.ECharts | null>(null);
    // const [data, setData] = useState<QuotationsAwardsData[]>([]);
    // useEffect(() => {
        // Mock data with invoiced values
        // const mockData = [
        //     { month: 'January', year: '2024', quotations: 9, awards: 4, invoiced: 1129863 },
        //     { month: 'February', year: '2024', quotations: 13, awards: 6, invoiced: 1133325 },
        //     { month: 'March', year: '2024', quotations: 11, awards: 7, invoiced: 1133189 },
        //     { month: 'April', year: '2024', quotations: 13, awards: 9, invoiced: 1128699 },
        //     { month: 'May', year: '2024', quotations: 10, awards: 8, invoiced: 1125230 },
        //     { month: 'June', year: '2024', quotations: 20, awards: 13, invoiced: 1131501 },
        //     { month: 'July', year: '2024', quotations: 19, awards: 17, invoiced: 1134877 },
        //     { month: 'August', year: '2024', quotations: 26, awards: 15, invoiced: 1134251 },
        //     { month: 'September', year: '2024', quotations: 25, awards: 19, invoiced: 1124623 },
        //     { month: 'October', year: '2024', quotations: 38, awards: 20, invoiced: 2260082 }
        // ];
        // setData(mockData);
    // }, []);

    const initializeChart = (chartData: QuotationsAwardsData[]) => {
        if (chartRef.current) {
            if (!chartInstance.current) {
                chartInstance.current = echarts.init(chartRef.current);
            }

            const months = chartData.map((item) => `${item.month} ${item.year}`);
            const quotations = chartData.map((item) => item.quotations);
            const awards = chartData.map((item) => item.awards);

            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any) => {
                        const quotation = params[0].value;
                        const award = params[1].value;
                        const invoiced = chartData[params[0].dataIndex].invoiced; // Get invoiced value for the month
                        const avgRate =(award&&quotations)?((award / quotation) * 100).toFixed(2):0; // Calculate the average award rate
                                // Invoiced Value: ${formatCurrency(invoiced, 'INR')}<br/>
                        return `
                            <div>
                                <strong>${params[0].name}</strong><br/>
                                Quotations: ${quotation}<br/>
                                Awards: ${award}<br/>
                                Avg Award Rate: ${avgRate}%
                            </div>
                        `;
                    },
                },
                xAxis: {
                    type: 'category',
                    data: months,
                    name: 'Month',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 20,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: `Count`,
                    nameLocation: 'middle',
                    axisLabel: {
                        formatter: (value: number) => value.toString(),
                    },
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 50,
                    },
                },
                grid: {
                    left: '3%',
                    right: '0',
                    bottom: '10%',
                    top: '8%',
                    containLabel: true,
                },
                series: [
                    {
                        name: 'Quotations',
                        type: 'line',
                        data: quotations,
                        lineStyle: {
                            color: '#3BAEA3',
                        },
                        smooth: true,
                    },
                    {
                        name: 'Awards',
                        type: 'line',
                        data: awards,
                        lineStyle: {
                            color: '#5470C6',
                        },
                        smooth: true,
                    },
                ],
            };

            chartInstance.current.setOption(option);
        }
    };

    useEffect(() => {
        if (data.length > 0) {
            initializeChart(data);
        } else {
            disposeChart();
        }
    }, [data]);

    const disposeChart = () => {
        if (chartInstance.current) {
            chartInstance.current.dispose();
            chartInstance.current = null;
        }
    };

    return (
        <div className=''>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Quotations vs Awards</h6>
                    <p>Month-on-Month comparison between quotations, awards, and invoiced value.</p>
                </div>
            </div>
            {data.length > 0 ? (
                <div ref={chartRef} id="quotations-awards-chart" className='dashboard-main-graph' style={{ width: '100%', height: '400px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                    <Empty description="No data found" />
                </div>
            )}
        </div>
    );
};

export default QuotationsVsAwardsChart;
