import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PaperClipOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Empty,
} from "antd";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import dayjs from "dayjs";
import { Badge, Button, Col, Row } from "reactstrap";
import invoiceApis from "../../api/Invoice/invoice";
import { nanoid } from "nanoid";
import Swal from "sweetalert2";
import usersApis from "../../api/master/users";
import dispatchApis from "../../api/dispatch/dispatch";
import statusMapper from "./StatusMapper";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import PdfGenerator from "./SellerToBuyerDispatchPDF";
import { formatDecimal } from "../../utils/Utils";
import axios from "axios";
import miscService from "../../api/misc/misc";

const { Panel } = AntCollapse;
function DispatchComponent({ dispatchNumber, previousPath }: any) {
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  const navigate = useNavigate();
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState("1");
  const [OriginalData, setOriginalData] = useState<any>({});
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [sm, setSm] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [signature, setSignature] = useState<any>([]);
  const [termsVisible, setTermsVisible] = useState(false);
  const [bankDetailsVisible, setBankDetailsVisible] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [dms, setDms] = useState<any>([]);
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [currency, setCurrency] = useState<any>({
    currencyFormat: "",
    currencySymbol: "",
  });
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [invoiceDisapproveInvoice, setInvoiceDisapproveInvoice] =
    useState<any>(false);
  const [readOnly, setReadOnly] = useState<any>(true);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverList, setApproverList] = useState<any>([]);
  const isEditing = (record: any) => record.key === editingKey;
  const [attachments, setAttachments] = useState<any>([]);

  const edit = (record: any) => {
    form.setFieldsValue({ quantity: "", unitPrice: "", ...record });
    setEditingKey(record.key);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
      render: (material: any) => <div className="align-left">{material}</div>,
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Qty dispatched",
      dataIndex: "quantityDispatched",
      key: "quantityDispatched",
      width: 150,
      render: (text: any, record: any) => (
        <div className="align-left">{Number(text) + " " + record?.uom}</div>
      ),
    },
    {
      title: "Rate",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      editable: true,
      render: (text: any, record: any) => (
        <p className="justify-end" onClick={() => edit(record)}>
          {FormatCurrency(
            Number(text),
            currency?.currencyFormat,
            currency?.currencySymbol
          )}
        </p>
      ),
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 150,
    //   fixed: "right",
    //   render: (status: any) => {
    //     const _status = statusMapper[status]?.buyer;
    //     return (
    //       <div className="justify-start align-center">
    //         <h6
    //           className={`tb-status ms-1`}
    //           style={{
    //             color: `${_status?.color}`,
    //             fontSize: "14px",
    //           }}
    //         >
    //           {_status?.icon} {_status?.title || status}
    //         </h6>
    //       </div>
    //     );
    //   },
    // },
  ];

  const mergedColumns: any = columns.map((col) => {
    if (
      ["basicAmount", "netAmount", "additionalCharges", "grossTotal"].includes(
        col?.dataIndex as string
      )
    ) {
      return {
        ...col,
        render: (text: any) => (
          <p className="justify-end">
            {FormatCurrency(
              Number(text),
              currency?.currencyFormat,
              currency?.currencySymbol
            )}
          </p>
        ),
      };
    } else if ((col.children?.length as number) > 0) {
      return {
        ...col,
        children: col?.children?.map((child: any) => {
          if (child?.title == "Amount") {
            return {
              ...child,
              render: (text: any) => (
                <p className="justify-end">
                  {FormatCurrency(
                    Number(text),
                    currency?.currencyFormat,
                    currency?.currencySymbol
                  )}
                </p>
              ),
            };
          } else {
            return child;
          }
        }),
      };
    }
    if (
      materialData?.needByDateType != "Line Level" &&
      col?.dataIndex == "needByDate"
    ) {
      return {
        ...col,
        hidden: true,
      };
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const fetchCurrentUser = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        setCurrentUserId(data?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchDispatchData = async () => {
    try {
      const response = await dispatchApis.fetchDispatch(dispatchNumber);
      const { status, message, data } = response;
      if (status) {
        if (data?.orderApprovals) {
          let ids = data?.orderApprovals?.map((item: any) => item?.userId);
          ids = ids?.filter((item: any) => status != "Approved");
          setApproverList(ids);
        }
        if (data?.additionalChargesArray) {
          setAdditionalCharges(
            data?.additionalChargesArray?.map((charge: any) => {
              const id = nanoid(4);
              form2.setFieldsValue({
                [`AdditionalCharges.${id}.name`]: charge?.name,
                [`AdditionalCharges.${id}.price`]: Number(charge?.price),
              });
              return {
                ...charge,
                id,
              };
            })
          );
        }
        if (data?.lineItems) {
          setDataSource(data?.lineItems);
        }
        if (data?.dms?.length > 0) {
          const dms = data?.dms;
          setDms(dms);
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: data?.signatureLabel,
              isActive: true,
            });
          }
          // const _termsAndConditions = dms?.find(
          //   (item: any) =>
          //     item.moduleFileType == "termsAndConditions" &&
          //     item.module == "invoice"
          // );

          const _attachments = dms?.filter(
            (item: any) => item.moduleFileType == "attachment"
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachment",
                isActive: true,
              }))
            );
          }
        }
        setCurrency({
          currencyCode: data?.currencyCode,
          currency: data?.currencyName,
          currencySymbol: data?.currencySymbol,
          currencyFormat: data?.currencyFormat,
        });
        if (
          [
            "Pending Approval",
            "Approval Rejected",
            "To be Dispatched",
            "Partially Dispatched",
          ].includes(data.invoiceStatus) &&
          data?.invoiceType != "commercial"
        ) {
          setReadOnly(false);
        } else {
          setReadOnly(true);
        }
        setMaterialData(data);
        setOriginalData(data);
        setShowSubmitBtn(false);
        setIsLoading(false);
        await fetchDMSData("buyerOrgLogo", data?.orgId);
        await fetchDMSData("sellerOrgLogo", data?.supplierId);
        await fetchDMSData("termsAndConditions", data?.invoiceNumber);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDMSData = async (type: string, id: any) => {
    try {
      const queryObj: any = {};
      switch (type) {
        case "buyerOrgLogo":
          queryObj["moduleFileType"] = "org logo";
          queryObj["module"] = "orgs";
          queryObj["orgId"] = id;
          break;
        case "sellerOrgLogo":
          queryObj["moduleFileType"] = "org logo";
          queryObj["module"] = "orgs";
          queryObj["orgId"] = id;
          break;
        case "termsAndConditions":
          queryObj["moduleFileType"] = "termsAndConditions";
          queryObj["module"] = "invoice";
          queryObj["invoiceNumber"] = id;
          break;
        default:
          return;
      }
      const _response = await miscService.getByQuery(queryObj);
      const { status, response: dmsData } = _response;
      if (status && dmsData?.length > 0) {
        const item = dmsData[0];
        switch (type) {
          case "buyerOrgLogo":
            let orgLogo = item?.filePath;
            setOriginalData((pre: any) => ({ ...pre, buyerOrgLogo: orgLogo }));
            break;
          case "sellerOrgLogo":
            let sellerOrgLogo = item?.filePath;
            setOriginalData((pre: any) => ({ ...pre, sellerOrgLogo: sellerOrgLogo }));
            break;
          case "termsAndConditions":
            const _termsAndConditions: any = await fetchHtmlContentFromUrl(
              item?.filePath
            );
            setTermsAndConditions(_termsAndConditions);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchDispatchData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (dispatchNumber) {
      setIsLoading(true);
      fetchDispatchData();
    }
  }, [dispatchNumber]);

  const ShowBillingAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    return (
      <ul>
        <li>{deliveryLocationName}</li>
        <li>{addressLine1}</li>
        <li>{addressLine2}</li>
        <li>
          {city},{district}, {state}, {countryInfo} - {pincode}
        </li>
      </ul>
    );
  };
  const ShowDeliveryAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    // const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressLine1},{addressLine2}
        </li>
        <li>
          {city},{district}, {state}, {countryInfo} - {pincode}
        </li>
        {/* <li>
          <strong>GSTIN</strong> : {gstin}
        </li>
        <li>
          <strong>CIN</strong> : {cin}
        </li>
        <li>
          <strong>PAN</strong> : {pan}
        </li>
        <li>
          <strong>Phone</strong> : {phoneNumber}
        </li> */}
      </ul>
    );
  };

  const ShowSupplierBankInfo = () => {
    if (!OriginalData?.supplierDetails) return <></>;

    const { cin, gstin, pan, phoneNumber, bankDetails, address } =
      OriginalData?.supplierDetails || {};

    const { bankName, accountNumber, accountHolderName, branch, ifscCode } =
      bankDetails || {};

    const { city, stateInfo, countryInfo, pincode } = address || {};

    return (
      <Descriptions
        title="Supplier Bank Details "
        bordered
        column={1}
        size="small"
      >
        <Descriptions.Item label="GSTIN">{gstin}</Descriptions.Item>
        <Descriptions.Item label="CIN">{cin}</Descriptions.Item>
        <Descriptions.Item label="PAN">{pan}</Descriptions.Item>
        <Descriptions.Item label="Phone">{phoneNumber}</Descriptions.Item>
        <Descriptions.Item label="Bank Name">{bankName}</Descriptions.Item>
        <Descriptions.Item label="Account Number">
          {accountNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Account Holder Name">
          {accountHolderName}
        </Descriptions.Item>
        <Descriptions.Item label="Branch">{branch}</Descriptions.Item>
        <Descriptions.Item label="IFSC Code">{ifscCode}</Descriptions.Item>
        <Descriptions.Item label="City">{city}</Descriptions.Item>
        <Descriptions.Item label="State">
          {stateInfo?.stateName}
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          {countryInfo?.countryName}
        </Descriptions.Item>
        <Descriptions.Item label="Pincode">{pincode}</Descriptions.Item>
      </Descriptions>
    );
  };
  const DisapproveInvoice = async (reasonForRejection: string) => {
    try {
      const response = await invoiceApis.disapproveInvoice(
        dispatchNumber,
        reasonForRejection
      );
      if (response.status) {
        Swal.fire("Rejected!", "Invoice has been rejected", "success");
      }
    } catch (error) {}
  };

  const returnBillingDetails = () => {
    if (!OriginalData?.billingAddress) {
      return {};
    }
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = OriginalData?.billingAddress;
    const { cin, gstin, pan } = OriginalData?.orgDetails || {};
    return {
      name: OriginalData?.orgName || "",
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      pincode: pincode,
      country: countryInfo,
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };

 const returnSupplierDetails = () => {
    if (!OriginalData?.supplierDetails) {
      return {};
    }
    const { cin, gstin, pan, phoneNumber, bankDetails, address } =
      OriginalData?.supplierDetails || {};

    const { bankName, accountNumber, accountHolderName, branch, ifscCode } =
      bankDetails || {};

    const { city, stateInfo, countryInfo, pincode,addressline1,addressline2 } = address || {};
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: addressline1 || "",
      addressLine2: addressline2 || "",
      city: city || "",
      state: stateInfo?.stateName || "",
      pincode: pincode || "",
      country: countryInfo?.countryName || "",
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };
  const returnDispatchDetails = () => {
    let deliveryAddressString = "";
    if (
      OriginalData?.deliveryAddressType != "Line Level" &&
      OriginalData?.deliveryAddress
    ) {
      const {
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city,
        state,
        countryInfo,
        pincode,
        district,
      } = OriginalData?.deliveryAddress;
      deliveryAddressString = [
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city + ", " + state + ", " + district,
        countryInfo + ", " + pincode,
      ]
        ?.filter(Boolean)
        .join("\n");
    } else {
      deliveryAddressString = "Line Level";
    }
    const { driverInfo } = OriginalData;
    return {
      invoiceNumber: OriginalData?.invoiceId,
      invoiceDate: dayjs(OriginalData?.invoiceDate).format("DD-MMM-YYYY"),

      terms:
        materialData?.paymentTerms +
        (materialData?.advancePercentage
          ? `(${materialData?.advancePercentage}%)`
          : ""),
      customSONumber: "",
      ewayBillNumber: OriginalData?.ewbNo||"",
      placeofSupply: OriginalData?.deliveryAddress?.city || "",
      creditDays:
        materialData?.paymentTerms != "Cash and Carry"
          ? materialData?.creditDays
          : 0,
      deliveryAddressString,
      vehicleRefNo: driverInfo?.vehicleNumber || "",
      transporterName: driverInfo?.name || "",
      orderNumber: OriginalData?.orderId,
      orderDate: dayjs(OriginalData?.orderDate).format("DD-MMM-YYYY"),
      driverNumber: driverInfo?.mobile || "",

      totals: {
        taxableAmount: formatDecimal(materialData?.netAmount),
        cgst: formatDecimal(materialData?.cgst),
        sgst: formatDecimal(materialData?.sgst),
        igst: formatDecimal(materialData?.igst),
        total: formatDecimal(materialData?.grossTotal),
      },
      lines: dataSource?.map((item: any, index: any) => {
        let itemDescription = item?.materialName;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        let deliveryAddressString = "";
        if (
          OriginalData?.deliveryAddressType == "Line Level" &&
          item?.deliveryAddress
        ) {
          const {
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          } = OriginalData?.deliveryAddress;
          deliveryAddressString = [
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          ]
            ?.filter(Boolean)
            .join(",");
          itemDescription += "\n\nDelivery Address: " + deliveryAddressString;
        }

        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: Number(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: Number(item?.cgst),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: Number(item?.sgst),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: Number(item?.igst),
          taxableAmount: Number(item?.grossTotal),
          qty: Number(item?.quantityInvoiced),
          uom: item?.uomName,
        };
      }),
    };
  };

  return (
    <div className="card-inner card-inner-lg">
      {sm && mobileView && (
        <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>
      )}
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">
              <span>
                {OriginalData?.orderId} |{OriginalData?.invoiceId} |
                {OriginalData?.dispatchId}{" "}
              </span>
            </BlockTitle>
          </BlockHeadContent>

          <RejectInvoice
            isModalVisible={invoiceDisapproveInvoice}
            setIsModalVisible={setInvoiceDisapproveInvoice}
            rejectInvoice={DisapproveInvoice}
          />

          <div className=" d-flex">
            <div className="paddingLeft10">
              <ul className="m-auto d-flex align-items-center justify-content-center p-0">
                <li className="pt-0 pb-0">
                  <PdfGenerator
                    orgDetails1={returnSupplierDetails()}
                    orgDetails2={returnBillingDetails()}
                    HeaderDetails={returnDispatchDetails()}
                    buyerOrgLogo={OriginalData?.sellerOrgLogo}
                    hasIGST={Number(materialData?.igst) != 0}
                    TermsAndConditions={termsAndConditions}
                  />
                </li>
                <li className="pt-0 pb-0">
                  <Button
                    className="toggle"
                    color="primary"
                    onClick={async () => {
                      navigate(previousPath);
                    }}
                  >
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </BlockBetween>
      </BlockHead>
      {isLoading == true ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : (
        <div className="content clearfix">
          <AntCollapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="General Information" key="1">
              <div className="tab-content mt-0">
                <div className={`tab-pane${tab === "1" && " active"}`}>
                  <Col className="justify-start w-100  flex-wrap  p-0">
                    <div className="w-40 p-1 pt-0 pb-0 border-end">
                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-40">Driver Name</div>
                        <div className="data-value w-50">
                          <span>{OriginalData?.driverInfo?.name}</span>
                        </div>
                      </div>

                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-40">Email</div>
                        <div className="data-value w-50">
                          <span>{OriginalData?.driverInfo?.email}</span>
                        </div>
                      </div>
                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-40">Mobile</div>
                        <div className="data-value w-50">
                          <span>{OriginalData?.driverInfo?.mobile}</span>
                        </div>
                      </div>
                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-40">Vehicle Type</div>
                        <div className="data-value w-50">
                          <span>{OriginalData?.driverInfo?.vehicleType}</span>
                        </div>
                      </div>
                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-40">Vehicle Number</div>
                        <div className="data-value w-50">
                          <span>{OriginalData?.driverInfo?.vehicleNumber}</span>
                        </div>
                      </div>
                    </div>

                    <div className="w-25  paddingLeft10 border-end">
                      <div className=" pt-0">
                        <div className="data-label w-90 mb-1">
                          Billing Address
                        </div>
                        <div className="data-value w-90">
                          <ShowBillingAddress
                            address={materialData?.billingAddress}
                          />
                        </div>
                      </div>
                    </div>
                    {materialData?.deliveryAddressId && (
                      <div className="w-35 paddingLeft10 ">
                        <div className=" pt-0">
                          <div className="data-label w-9 mb-1">
                            Delivery Address
                          </div>
                          <div className="data-value w-90">
                            <ShowDeliveryAddress
                              address={materialData?.deliveryAddress}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </div>
              </div>
            </Panel>
            <Panel header="Material Informations" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 350 + "px",
                    margin: "0px auto",
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      className="customTable"
                      columns={mergedColumns.map((col: any) => {
                        if (col.key == "actions") {
                          return {
                            ...col,
                            hidden: readOnly,
                          };
                        }
                        return col;
                      })}
                      dataSource={dataSource.map((item: any) => ({
                        ...item,
                        quantityInvoiced: Number(item?.quantityInvoiced),
                        unitPrice: Number(item?.unitPrice),
                        key: item?.lineNo,
                      }))}
                      scroll={{ x: 1000 }}
                      expandable={{
                        expandedRowRender: (record: any) => (
                          <div className="expandableBody">
                            <div style={{ flex: 1, textAlign: "left" }}>
                              <p style={{ margin: 0 }}>{record.description}</p>
                              {record.file && (
                                <div>
                                  <PaperClipOutlined /> &nbsp;
                                  <a
                                    href={record?.file?.fileName}
                                    download={record?.file?.fileName}
                                  >
                                    {record?.file?.fileName}
                                  </a>
                                </div>
                              )}
                            </div>
                            {materialData?.deliveryAddressType ===
                              "Line Level" &&
                              record.deliveryAddressId && (
                                <div
                                  className="lineAddress"
                                  style={{ flex: 1 }}
                                >
                                  <h6>Delivery Address</h6>
                                  <ShowDeliveryAddress
                                    address={record.deliveryAddress}
                                  />
                                </div>
                              )}
                          </div>
                        ),
                      }}
                      bordered
                      size="middle"
                      pagination={false}
                    />
                  </Form>
                </div>

                <Row className="m-4 justify-content-end ">
                  {signature?.isActive && (
                    <div className="signatureBtnWrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Signature</strong>
                      </div>
                      <div
                        style={{
                          minHeight: signature?.image ? "0px" : "120px",
                          display: signature?.image ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {signature?.image && (
                          <>
                            <div className="signatueImgWrapper border">
                              <img
                                src={signature?.image}
                                alt="Signed"
                                style={{ width: "160px" }}
                              />
                            </div>

                            <div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="signatureLabel"
                                >
                                  Signature Label
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="signatureLabel"
                                  onChange={(e) => {}}
                                  disabled
                                  defaultValue={
                                    signature?.label || "Authorised Signatory"
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Panel>
            <Panel header="Attachments" key="3">
              {attachments?.length > 0 ? (
                <Descriptions.Item label="">
                  <div>
                    <strong className="">
                      Attachments &nbsp;
                      <PaperClipOutlined />
                    </strong>
                    <div>
                      {attachments?.map((attachment: any, index: number) => (
                        <div
                          key={index}
                          className="w-100 d-flex justify-content-between"
                        >
                          <div>
                            <strong>{index + 1}.</strong>
                            &nbsp;
                            {attachment.name}
                            &nbsp; &nbsp; &nbsp; &nbsp;
                          </div>
                          <div>
                            <a
                              href={attachment.link}
                              download={attachment.link}
                              style={{
                                color: "black",
                                fontSize: "18px",
                              }}
                            >
                              <DownloadOutlined />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Descriptions.Item>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <Empty description="No Attachments Provided" />
                </div>
              )}
            </Panel>
          </AntCollapse>
        </div>
      )}
    </div>
  );
}

const RejectInvoice = ({
  isModalVisible,
  setIsModalVisible,
  rejectInvoice,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        rejectInvoice(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <AntModal
      title="Reject Purchase Order"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Reason for rejection"
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

export default DispatchComponent;
