import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  AlertOutlined,
} from "@ant-design/icons";

const statusMapper: any = {

  "Partially Dispatched": {
    buyer: {
      title: "Partially Dispatched",
      color: "purple", 
      icon: <ExclamationCircleOutlined />, 
    },
    seller: {
      title: "Partially Dispatched",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Fully Dispatched": {
    buyer: {
      title: "Fully Dispatched",
      color: "green", 
      icon: <CheckCircleOutlined />, 
    },
    seller: {
      title: "Fully Dispatched",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "To be Dispatched": {
    buyer: {
      title: "To be Dispatched",
      color: "blue", 
      icon: <SyncOutlined spin />, 
    },
    seller: {
      title: "To be Dispatched",
      color: "blue",
      icon: <SyncOutlined spin />,
    },
  },
  // Intransit: {
  //   buyer: {
  //     title: "Intransit",
  //     color: "purple", 
  //     icon: <ClockCircleOutlined />, 
  //   },
  //   seller: {
  //     title: "Intransit",
  //     color: "purple",
  //     icon: <ClockCircleOutlined />,
  //   },
  // }
};

export default statusMapper;
