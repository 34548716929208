import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { formatCurrency } from '../../utils/Utils';  // Import formatCurrency utility

const OverdueSummaryWidget: React.FC<any> = ({data}) => {
    // const data = [
    //     { name: '0-15 Days', value: 921144.5 },    // ₹4,79,703
    //     { name: '16-30 Days', value: 307048.2 },   // ₹2,90,522
    //     { name: '31-45 Days', value: 76762.04 },   // ₹4,14,959
    //     { name: '46-60 Days', value: 12793.67 },   // ₹1,77,947
    //     { name: '>60 Days', value: 524540.6 },     // ₹3,27,258
    // ];

    useEffect(() => {
        const chartDom = document.getElementById('overdue-summary-widget') as HTMLElement;
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
                formatter: (params: any) => {
                    return `${params.name}: ${formatCurrency(params.value, 'INR')} (${params.percent}%)`;  // Use formatCurrency for values
                },
            },
            series: [
                {
                    name: 'Over Due Summary',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        formatter: (params: any) => {
                            return `{b|${params.name}}\n{c|${formatCurrency(params.value, 'INR')} (${params.percent}%)}`
                        },
                        rich: {
                            b: {
                                fontSize: 14,
                                color: '#333',
                                lineHeight: 24,
                            },
                            c: {
                                fontSize: 12,
                                color: '#999',
                                lineHeight: 20,
                            },
                        },
                    },
                    labelLine: {
                        show: true,
                        length: 15,
                        length2: 10,
                        smooth: true,
                    },
                    itemStyle: {
                        borderColor: '#E0E4FF',  // Border color
                        borderWidth: 1,           // Border width
                        shadowBlur: 10,           // Shadow blur
                        shadowOffsetX: 0,         // Shadow X offset
                        shadowOffsetY: 5,         // Shadow Y offset
                        shadowColor: 'rgba(255, 255, 255, 0.2)', // Shadow color
                        borderRadius: 5,
                    },
                    data: data.map((item:any) => ({ value: item.value, name: item.name })),
                },
            ],
            color: ['#173F5F', '#20639B', '#3CAEA3', '#F6D55C', '#ED553B'],
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [data]);

    return (
        <>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Over Due Summary</h6>
                    <p>
                        Detailed view of overdue payments categorized by aging periods.
                    </p>
                </div>
            </div>
            <div id="overdue-summary-widget" style={{ width: '100%', height: '300px' }} />
        </>
    );
};

export default OverdueSummaryWidget;
