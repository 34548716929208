import React, { useState, useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { FormProvider, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  Popover,
  DatePicker,
  message,
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import purchaseOrderApis from "../../api/purchase-order/PurchaseOrder";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "./QuotationsAside";
import statusMapper from "../purchase-orders/StatusMapper";
import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import usersApis from "../../api/master/users";
import quotationApis from "../../api/master/quotations";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

const RFQViewDetails: FC<any> = () => {
  const {
    data: currentUser,
    loading,
    error,
  } = useSelector((state: RootState) => state.currentUser);

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableRegret, setDisableRegret] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { orderNumber }: any = useParams();
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [invoiceableLines, setInvoiceableLines] = useState<any>([]);
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [poStatus, setPoStatus] = useState<any>("");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [documentsAttached, setDocumentsAttached] = useState<any>([]);
  const [signature, setSignature] = useState<any>([]);
  const [invoiceSignature, setInvoiceSignature] = useState<any>({});
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [approvalRequired, setApprovalRequired] = useState("false");
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const setColumns = (columns: any) => {};

  const location = useLocation();
  const rfq = location.state?.rfq;

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editingKeys, setEditingKeys] = useState<string[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editAdditionalCharges, setEditAdditionalCharges] = useState(true);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<any>(null);
  const [selectedNeedByDate, setSelectedNeedByDate] = useState<any>(null);
  const [needByDateList, setNeedByDateList] = useState<any>([]);
  const isEditing = (record: any) => editingKeys.includes(record.rfqLineId);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [prefferedStates, setPrefferedStates] = useState<any>([]);

  const [paymentTerm, setPaymentTerm] = useState<string | undefined>(undefined);

  const [taxData, setTaxableData] = useState({});

  const [requiredDOC, setRequiredDOC]: any = useState();

  const [currentUserAddresses, setCurrentUserAddresses] = useState<any>([]);

  const [disableCreateQuoteSubmit, setDisableCreateQuoteSubmit] =
    useState(false);

  const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [supplierTermsAndConditions, setsupplierTermsAndConditions] =
    useState<any>("");

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  // Handle payment term change
  const handlePaymentTermChange = (value: string) => {
    setPaymentTerm(value);
  };

  useEffect(() => {
    if (currentUser) {
      console.log("currentUser", currentUser);
      //   const { registeredAddress} = currentUser;
      if (currentUser?.registeredAddress?.id) {
        setCurrentUserAddresses(currentUser?.registeredAddress?.id);
      }
    }
  }, [currentUser]);

  const areAllRowsFilled = () => {
    let allFilled = true;

    selectedRowKeys.forEach((key) => {
      const row = data.find((item: any) => item.quotationLineId == key);
      if (row) {
        const hsn = form.getFieldValue(`hsn_${row.rfqLineId}`);
        const price = form.getFieldValue(`price_${row.rfqLineId}`);
        const leadDays = form.getFieldValue(`leadDays_${row.rfqLineId}`);

        // Check if any required field is missing or invalid
        if (!hsn || !price || leadDays === undefined || leadDays <= 0) {
          allFilled = false;
        }
      }
    });

    return allFilled;
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    const handleChange = (value: any) => {
      form.setFieldsValue({ [`${dataIndex}_${record.rfqLineId}`]: value });
    };

    const validateMinPurchaseQuantity = (rule: any, value: any) => {
      const supplyQuantity =
        form.getFieldValue(`supplyQuantity_${record.rfqLineId}`) ||
        record.quantity ||
        0;
      if (value > supplyQuantity) {
        return Promise.reject(
          `${title} should not be greater than Supply Quantity (${supplyQuantity})`
        );
      }
      return Promise.resolve();
    };

    const validateLeadDays = (rule: any, value: any) => {
      if (value <= 0) {
        return Promise.reject(`${title} must be greater than 0`);
      }
      return Promise.resolve();
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex + `_${record.rfqLineId}`}
            style={{ margin: 0 }}
            initialValue={record[dataIndex]}
            rules={
              dataIndex === "leadDays"
                ? [
                    {
                      validator: validateLeadDays,
                    },
                  ]
                : dataIndex === "minPurchaseQuantity"
                ? [
                    {
                      validator: validateMinPurchaseQuantity,
                    },
                  ]
                : dataIndex === "price" ||
                  dataIndex === "supplyQuantity" ||
                  dataIndex === "minPurchaseQuantity" ||
                  dataIndex === "leadDays"
                ? [
                    {
                      type: "number",
                      min: 1,
                      message: `${title} must be a whole number greater than 0`,
                    },
                  ]
                : dataIndex === "cgst" ||
                  dataIndex === "igst" ||
                  dataIndex === "sgst"
                ? [
                    {
                      type: "number",
                      min: 0,
                      message: `${title} must be greater than or equal to 0`,
                    },
                  ]
                : dataIndex === "leadDays"
                ? [
                    {
                      type: "number",
                      min: 1,
                      message: "Lead Days must be greater than 0",
                    },
                  ]
                : []
            }
          >
            {dataIndex === "hsn" ? (
              <Form.Item
                name={dataIndex + `_${record.rfqLineId}`}
                style={{ margin: 0 }}
                rules={[
                  {
                    pattern: /^[0-9]{6,8}$/,
                    message: "HSN should be a number with 6 to 8 digits",
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  parser={(value: any) => value.replace(/\D/g, "")} // Removes non-numeric characters
                  onKeyPress={(event) => {
                    const regex = /^[0-9\b]+$/; // Restrict to numbers and backspace
                    if (!regex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={form.getFieldValue(`${dataIndex}_${record.rfqLineId}`)}
                  onChange={handleChange}
                />
              </Form.Item>
            ) : (
              <InputNumber
                className="w-100"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={handleChange}
                defaultValue={Number(record[dataIndex])}
                min={
                  dataIndex === "cgst" ||
                  dataIndex === "igst" ||
                  dataIndex === "sgst" ||
                  dataIndex === "supplyQuantity" ||
                  dataIndex === "minPurchaseQuantity"
                    ? 0 // Allow decimal values for tax fields
                    : 1 // Enforce integer values for supplyQuantity, minPurchaseQuantity, and leadDays
                }
                step={
                  dataIndex === "cgst" ||
                  dataIndex === "igst" ||
                  dataIndex === "sgst" ||
                  dataIndex === "price" ||
                  dataIndex === "supplyQuantity" ||
                  dataIndex === "minPurchaseQuantity"
                    ? 0.01 // Allow decimals for tax fields
                    : 1 // Force integer input for supplyQuantity, minPurchaseQuantity, leadDays
                }
                onKeyPress={(event) => {
                  if (
                    dataIndex === "cgst" ||
                    dataIndex === "igst" ||
                    dataIndex === "sgst" ||
                    dataIndex === "price" ||
                    dataIndex === "supplyQuantity" ||
                    dataIndex === "minPurchaseQuantity"
                  ) {
                    // Allow numbers and decimal point for tax fields
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  } else {
                    // Allow only numbers (no decimals) for other fields
                    if (!/^[0-9]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                }}
              />
            )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Material",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text: any, record: any) => `${Number(text)} ${record.uomName}`,
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "needByDate",
      width: 200,
      render: (text: any, record: any) =>
        `${dayjs(text).format("DD-MMM-YYYY")}`,
    },
    {
      title: "Supply Qty",
      dataIndex: `supplyQuantity`,
      key: "supplyQuantity",
      width: 120,
      editable: true,
    },
    {
      title: "Min Purchase Qty",
      dataIndex: `minPurchaseQuantity`,
      key: "minPurchaseQuantity",
      width: 150,
      editable: true,
    },
    {
      title: "HSN *",
      dataIndex: `hsn`,
      key: "hsn",
      width: 120,
      editable: true,
    },
    {
      title: "Price *",
      dataIndex: "price",
      key: "price",
      width: 150,
      editable: true,
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      editable: true,
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      editable: true,
    },
    {
      title: "IGST (%)",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      editable: true,
    },
    {
      title: "Lead Days *",
      dataIndex: "leadDays",
      key: "leadDays",
      width: 250,
      editable: true,
    },
    // {
    //   title: "Line Total",
    //   dataIndex: "lineTotal",
    //   key: "lineTotal",
    //   width: 250,
    //   render: (text: any, record: any) => {
    //     if (text) {
    //       return <div>{FormatCurrency(text, "INR")}</div>
    //     }
    //     else {
    //       // Retrieve current values from the form
    //       const supplyQuantity =
    //         form.getFieldValue(`supplyQuantity_${record.rfqLineId}`) || 0;
    //       const price = form.getFieldValue(`price_${record.rfqLineId}`) || 0;
    //       const sgst = form.getFieldValue(`sgst_${record.rfqLineId}`) || 0;
    //       const cgst = form.getFieldValue(`cgst_${record.rfqLineId}`) || 0;
    //       const igst = form.getFieldValue(`igst_${record.rfqLineId}`) || 0;

    //       // Calculate the line total
    //       const totalGst = sgst + cgst + igst;
    //       const lineTotal = supplyQuantity * price * (1 + totalGst / 100);

    //       return <div>{lineTotal.toFixed(2)}</div>;
    //     }
    //   },
    // },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        render: (text: any, record: any) => {
          if (col.dataIndex === "sgst") {
            return (
              <>
                {text} ({record.sgstData})
              </>
            );
          }
          if (col.dataIndex === "cgst") {
            return (
              <>
                {text} ({record.cgstData})
              </>
            );
          }
          if (col.dataIndex === "igst") {
            return (
              <>
                {text} ({record.igstData})
              </>
            );
          }
          return text;
        },
      }),
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (!newSelectedRowKeys) {
      editingKeys.forEach((key) => {
        console.log("key1111", key);
        const row = data.find((item: any) => item.quotationLineId == key);
        form.setFieldsValue({ [`supplyQuantity_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`minPurchaseQuantity_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`hsn_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`price_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`sgst_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`cgst_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`igst_${row.rfqLineId}`]: null });
        form.setFieldsValue({ [`leadDays_${row.rfqLineId}`]: null });
      });
      setEditingKeys(() => []);
      form.resetFields();
    }
    const unSelectedRowKeys = selectedRowKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );
    unSelectedRowKeys?.forEach((key) => {
      const row = data.find((item: any) => item.quotationLineId == key);
      form.setFieldsValue({ [`supplyQuantity_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`minPurchaseQuantity_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`hsn_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`price_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`sgst_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`cgst_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`igst_${row.rfqLineId}`]: null });
      form.setFieldsValue({ [`leadDays_${row.rfqLineId}`]: null });
    });
    const editableRowKeys: any = [];
    newSelectedRowKeys?.forEach((key) => {
      console.log("key", key);
      const row = data.find((item: any) => item.quotationLineId == key);
      if (row) {
        form.setFieldsValue({
          [`supplyQuantity_${row.rfqLineId}`]: row.quantity, // Auto-fill supplyQuantity with quantity
          [`minPurchaseQuantity_${row.rfqLineId}`]:
            row.minPurchaseQuantity || null,
          [`hsn_${row.rfqLineId}`]: row.hsn || null,
          [`price_${row.rfqLineId}`]: row.price || null,
          [`sgst_${row.rfqLineId}`]: row.sgst || null,
          [`cgst_${row.rfqLineId}`]: row.cgst || null,
          [`igst_${row.rfqLineId}`]: row.igst || null,
          [`leadDays_${row.rfqLineId}`]: row.leadDays || null,
        });
        editableRowKeys.push(row.rfqLineId);
      }
    });
    setEditingKeys(editableRowKeys);
    console.log("row.rfqLineId", editableRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChangeAttachment = (info: any) => {
    let files = info.fileList.slice(-3); // Limit to 3 files
    setDocumentsAttached(files);
  };

  const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 <= 2;
    const isValidType = ["application/pdf", "image/jpeg", "image/png"].includes(
      file.type
    );

    if (!isLt2M) {
      Swal.fire({
        icon: "error",
        title: "File size should be less than 2MB",
      });
    }

    if (!isValidType) {
      Swal.fire({
        icon: "error",
        title: "File type should be pdf, jpeg or png",
      });
    }

    return isLt2M && isValidType;
  };

  const showDrawer = async () => {
    try {
      const rows = await form.validateFields();
      const invoiceLines: any = [];
      console.log("DATA:::DRAER", data);
      data.forEach((item: any) => {
        const isEdited = editingKeys.includes(item.rfqLineId);
        const supplyQuantity = isEdited
          ? rows[`supplyQuantity_${item.rfqLineId}`] || item.quantity
          : item.supplyQuantity || item.quantity;
        const minPurchaseQuantity = isEdited
          ? rows[`minPurchaseQuantity_${item.rfqLineId}`]
          : item.minPurchaseQuantity;
        const hsn = isEdited ? rows[`hsn_${item.rfqLineId}`] : item.hsn;
        const price = isEdited ? rows[`price_${item.rfqLineId}`] : item.price;
        const sgstData = isEdited ? rows[`sgst_${item.rfqLineId}`] : item.sgst;
        const cgstData = isEdited ? rows[`cgst_${item.rfqLineId}`] : item.cgst;
        const igstData = isEdited
          ? rows[`igst_${item.rfqLineId}`]
          : item.igst || 0;
        const leadDays = isEdited
          ? rows[`leadDays_${item.rfqLineId}`]
          : item.leadDays;

        // Calculate the amounts
        const basicAmount = supplyQuantity * price;
        const sgst = (basicAmount * Number(sgstData)) / 100;
        const igst = (basicAmount * Number(igstData)) / 100;
        const cgst = (basicAmount * Number(cgstData)) / 100;
        invoiceLines.push({
          ...item,
          supplyQuantity,
          sgstData,
          cgstData,
          igstData,
          minPurchaseQuantity,
          basicAmount,
          sgst,
          igst,
          cgst,
          hsn,
          price,
          leadDays,
          grossTotal: basicAmount + sgst + igst + cgst,
        });
      });
      console.log("invoiceLines:::1111", invoiceLines);
      setInvoiceableLines(invoiceLines);
      setRequiredDOC(OriginalData?.quotationInfo?.requiredDocs);
      setDrawerVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const previewColumns = [
    {
      title: "Material",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },
    {
      title: "Supply Qty",
      dataIndex: "supplyQuantity",
      key: "supplyQuantity",
      width: 120,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      key: "sgst",
      width: 150,
      render: (text: any, record: any) =>
        `${record.sgstData || "0"} (${FormatCurrency(text || 0, "INR")})`,
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      key: "cgst",
      width: 150,
      render: (text: any, record: any) =>
        `${record.cgstData || "0"} (${FormatCurrency(text || 0, "INR")})`,
    },
    {
      title: "IGST (%)",
      dataIndex: "igst",
      key: "igst",
      width: 150,
      render: (text: any, record: any) =>
        `${record.igstData || "0"} (${FormatCurrency(text || 0, "INR")})`,
    },
    {
      title: "Line Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text: any) => FormatCurrency(text || 0, "INR"),
    },
  ];

  const closeDrawer = () => {
    form2.resetFields();
    setDrawerVisible(false);
  };

  const fetchCurrentUserInfo = async () => {
    try {
      const res = await usersApis.getCurrentUser();

      if (res) {
        const { prefferedStates } = res;
        setPrefferedStates(prefferedStates?.map((item: any) => item?.stateID));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrderDetails = async () => {
    console.log(" rfq.quotationId", rfq.quotationId);
    try {
      const res = await quotationApis.latestByQuotationId(rfq.quotationId);
      const { response, status, message } = res;
      if (status) {
        // const {
        //   currencyCode,
        //   currencySymbol,
        //   currencyFormat,
        //   dms,
        //   signatureLabel,
        //   igst,
        // } = data;
        setOriginalData(response.data);
        setPoStatus(data?.orderStatus);
        // if (Number(igst) == 0) {
        //   setTaxConfiguration({
        //     taxType: "GST",
        //     gstType: "CGST+SGST",
        //     cess: [],
        //   });
        // }
        setCurrency({
          description: "Indian Rupee",
          currency_code: "INR",
          currency_symbol: "₹",
          currency_format: "₹#,##0.00",
          example: "INR",
        });
        if (response?.data?.quotationInfo?.requiredDocs?.length > 0) {
          const _signature = response?.data?.quotationInfo?.requiredDocs?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: _signature?.signatureLabel,
              isActive: true,
            });
          }
        }

        console.log(
          "response?.data?.quotationInfo?.quotationHeader.dms",
          response?.data?.quotationInfo?.quotationHeader.dms
        );
        const _attachments =
          response?.data?.quotationInfo?.quotationHeader.dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
        console.log("attachments", _attachments);
        if (_attachments) {
          setAttachments(
            _attachments.map((attachment: any) => ({
              docId: attachment?.docId,
              name: attachment?.fileName,
              link: attachment?.filePath,
              moduleFileType: attachment?.moduleFileType,
              module: attachment?.module,
              label: "attachments",
              isActive: true,
            }))
          );
        }

        const _termsAndConditions =
          response?.data?.quotationInfo?.quotationHeader.dms?.find(
            (item: any) => item.moduleFileType == "termsAndConditions"
          );
        if (_termsAndConditions) {
          const data: any = await fetchHtmlContentFromUrl(
            _termsAndConditions?.filePath
          );
          setTermsAndConditions(data);
        }
        const _instructions =
          response?.data?.quotationInfo?.quotationHeader.dms?.find(
            (item: any) => item.moduleFileType == "instructions"
          );
        if (_instructions) {
          const data: any = await fetchHtmlContentFromUrl(
            _instructions?.filePath
          );
          setInstructions(data);
        }
        let materialData = response?.data?.supplierInfo?.supplierLines?.map(
          (item: any) => {
            return { ...item.quotationLines, ...item };
          }
        );
        console.log("materialData", materialData);
        setMaterialData(materialData);
        const _DeliveryAddressList: any = [];
        const _needByDateList: any = [];
        const LineItems = materialData?.map((item: any) => {
          if (
            response?.data?.quotationInfo?.quotationHeader?.deliveryDateType ==
            "LINE_LEVEL"
          ) {
            if (
              !_DeliveryAddressList?.find(
                (address: any) => address?.id == item?.deliveryAddressName?.id
              )
            ) {
              _DeliveryAddressList.push({ ...item?.deliveryAddressName });
            }
          }
          //   if (data?.needByDateType == "LINE_LEVEL") {
          //     if (
          //       !_needByDateList?.find((date: any) => date == item?.needByDate)
          //     ) {
          //       _needByDateList.push(item?.needByDate);
          //     }
          //   }
          const file = item?.dms;
          if (file) {
            item.file = file[0];
          }
          return item;
        });

        // setNeedByDateList(_needByDateList);
        console.log("DeliveryAddressList", _DeliveryAddressList);
        setDeliveryAddressList(_DeliveryAddressList);
        console.log("LiNeItems", LineItems);
        setData(LineItems);
        setFilterData(LineItems);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ViewTableTotals = ({
    data,
    additionalCharges,
    invoice,
    currency,
  }: any) => {
    console.log("invoiceableLines", invoiceableLines);
    let totalNetAmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      totalAmount = 0;

    invoiceableLines?.forEach((item: any) => {
      const price = item.price || 0;
      const supplyQuantity = item.supplyQuantity || 0;
      const cgstData = item.cgstData || 0;
      const sgstData = item.sgstData || 0;
      const igstData = item.igstData || 0;

      const totalAmountForItem = price * supplyQuantity;

      // Calculate CGST, SGST, and IGST based on valid amounts
      const itemCGST = (totalAmountForItem * cgstData) / 100;
      const itemSGST = (totalAmountForItem * sgstData) / 100;
      const itemIGST = (totalAmountForItem * igstData) / 100;

      // Add to totals
      totalNetAmount += totalAmountForItem;
      cgst += itemCGST;
      sgst += itemSGST;
      igst += itemIGST;
    });

    totalAmount = totalNetAmount + cgst + sgst + igst;

    // Additional charges, if any
    additionalCharges?.forEach((item: any) => {
      if (item.price) {
        totalAmount += item.price;
      }
    });
    // console.log("totalNetAmount",totalNetAmount)

    // setTaxableData({
    //   netTotal: totalNetAmount,
    //   cgst: cgst,
    //   sgst: sgst,
    //   igst: igst,
    //   gross: totalAmount,
    // });
    return (
      <Row
        className={`${invoice ? "w-50 mt-2" : ""}`}
        style={{ margin: "20px" }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {FormatCurrency(
                  totalNetAmount,
                  currency?.currency_format,
                  currency?.currency_symbol
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>CGST</strong>
              </div>
              <span className="r_align">
                {FormatCurrency(
                  cgst,
                  currency?.currency_format,
                  currency?.currency_symbol
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>SGST</strong>
              </div>
              <span className="r_align">
                {FormatCurrency(
                  sgst,
                  currency?.currency_format,
                  currency?.currency_symbol
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>IGST</strong>
              </div>
              <span className="r_align">
                {FormatCurrency(
                  igst,
                  currency?.currency_format,
                  currency?.currency_symbol
                )}
              </span>
            </div>
            {additionalCharges?.map((item: any) =>
              item.price ? (
                <div className="d-flex justify-content-between" key={item.name}>
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {FormatCurrency(
                      item.price,
                      currency?.currency_format,
                      currency?.currency_symbol
                    )}
                  </span>
                </div>
              ) : null
            )}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {FormatCurrency(
                  totalAmount,
                  currency?.currency_format,
                  currency?.currency_symbol
                )}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    fetchCurrentUserInfo();
    fetchOrderDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const ShowBillingAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressline1,
      addressline2,
      city,
      pincode,
      state,
      district,
      countryInfo,
    } = address;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressline1}
        </li>
        <li>{addressline2}</li>
        <li>
          {city}, {district?.districtName}, {state?.stateName},{" "}
          {countryInfo?.countryName} - {pincode}
        </li>
      </ul>
    );
  };
  const ShowDeliveryAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      pincode,
      state,
      district,
      countryInfo,
    } = address;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressLine1}
        </li>
        <li>{addressLine2}</li>
        <li>
          {city}, {district?.districtName}, {state?.stateName},{" "}
          {countryInfo?.countryName} - {pincode}
        </li>
      </ul>
    );
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
  };

  useEffect(() => {
    let _filterData = data;
    if (selectedNeedByDate) {
      _filterData = _filterData.filter(
        (item: any) => item.needByDate == selectedNeedByDate
      );
    }
    if (selectedDeliveryAddress) {
      _filterData = _filterData.filter(
        (item: any) => item.deliveryAddressId == selectedDeliveryAddress
      );
    }
    setFilterData(_filterData);
  }, [selectedDeliveryAddress, selectedNeedByDate, data]);

  const [fileList, setFileList]: any = useState({});

  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement & { files: FileList };
    if (target && target.files) {
      const newFileNames = Array.from(target.files).map((file) => file.name);
      setFileNames((prev) => [...prev, ...newFileNames]);
      // setValue('file', target.files);
    }
  };

  const createInvoice = async (Data: any) => {
    console.log(
      "createInvoice::::values",
      Data,
      invoiceableLines,
      invoiceSignature
    );
    try {
      const _formData = new FormData();
      const supplierApprovals: any = approvals?.data;
      const filesData = [];
      if (supplierTermsAndConditions) {
        const termsAndConditionsFile = new Blob([supplierTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        filesData.push({
          fileIndex: filesData.length,
          module: "Quotation",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
        });
      }
      if (Data?.attachDC) {
        Data?.attachDC?.forEach((item: any) => {
          _formData.append(
            `files`,
            new File([item], item.name, {
              type: item.type,
            })
          );
          filesData.push({
            fileIndex: filesData.length,
            module: "Quotation",
            moduleFileType: "attachments",
            level: "HEADER_LEVEL",
          });
        });
      }
      console.log("filesData", filesData);
      const docEntries = Object.entries(Data).filter(([key]) =>
        key.startsWith("doc_")
      );
      console.log("docKeys", docEntries);
      docEntries.forEach(([key, value]: any, index) => {
        const docNumberStr = key.slice(4); // Remove 'doc_' prefix
        const docNumber = parseInt(docNumberStr, 10);
        if (!isNaN(docNumber) && value && value.length > 0) {
          filesData.push({
            quotationDocNameId: docNumber,
            fileIndex: filesData.length,
            module: "Quotation",
            moduleFileType: "Supplier Docs",
            level: "LINE_LEVEL",
          });
          console.log("key", key, value);
          if (value[0]) {
            _formData.append(`files`, new File([value[0]], value[0]?.name));
          } else {
            console.log(`Skipping ${key} because value is undefined or empty`);
          }
        }
      });
      delete Data.attachDC;
      const keysToDelete = Object.keys(Data).filter((key) =>
        key.startsWith("doc_")
      );
      keysToDelete.forEach((key) => {
        delete Data[key]; // Delete each key from the copied object
      });
      let validityDate = Data?.invoiceDate?.format("YYYY-MM-DD");
      let paymentPercentage = Data?.advancePercentage;
      delete Data.invoiceDate;
      delete Data.advancePercentage;

      let approvalRequired = Data?.approvals;
      delete Data?.approvals;

      let totalNetAmount = 0,
        cgst = 0,
        sgst = 0,
        igst = 0,
        totalAmount = 0;

      invoiceableLines?.forEach((item: any) => {
        console.log("item", item, item.price * item.supplyQuantity);
        const totalAmountForItem = item.price * item.supplyQuantity;
        console.log("111", totalAmountForItem);
        const itemCGST = (totalAmountForItem * item.cgstData) / 100;
        console.log("itemCGST", itemCGST);
        const itemSGST = (totalAmountForItem * item.sgstData) / 100;
        console.log("itemSGST", itemSGST);
        const itemIGST = (totalAmountForItem * item.igstData) / 100;
        console.log("itemIGST", itemIGST);

        totalNetAmount += totalAmountForItem;
        cgst += itemCGST;
        sgst += itemSGST;
        igst += itemIGST;
      });
      console.log("totalNetAmount", totalNetAmount);

      totalAmount = totalNetAmount + cgst + sgst + igst;

      const supplierData: any = {
        supplierHeader: {
          ...Data,
          approvalRequired,
          validityDate,
          paymentPercentage,
          netTotal: totalNetAmount,
          cgst: cgst.toFixed(2),
          sgst: sgst.toFixed(2),
          igst: igst.toFixed(2),
          gross: totalAmount,
          status: "QUOTED",
          signatureLabel: invoiceSignature?.label || "Authorised Signatory",
          quotationId: OriginalData.quotationInfo.quotationHeader.quotationId,
          rfqId: OriginalData.quotationInfo.quotationHeader.rfqId,
          // supplierId: 110,
          additionalChargesArray: additionalCharges?.map((charge: any) => {
            return {
              name: charge?.name,
              price: charge?.price,
            };
          }),
        },
        supplierLines: invoiceableLines.map((item: any) => {
          return {
            // supplierQuoteLineId:
            // supplierQuoteId: 123,
            quotationId: OriginalData.quotationInfo.quotationHeader.quotationId,
            rfqId: OriginalData.quotationInfo.quotationHeader.rfqId,
            // version: 1,
            quotationLineId: item.quotationLineId,
            minQty: item.minPurchaseQuantity,
            suppliedQty: item.supplyQuantity,
            hsn: item.hsn,
            price: item.price,
            sgst: Number(item?.sgstData?.toFixed(2)),
            cgst: Number(item?.cgstData?.toFixed(2)),
            igst: Number(item?.igstData?.toFixed(2)),
            leadDays: item.leadDays,
            lineTotal: item.grossTotal,
          };
        }),
      };

      console.log("taxData", supplierData.supplierHeader);

      if (invoiceSignature?.image) {
        const image = base64ToFile(invoiceSignature?.image, "signature.png");
        supplierData.signatureLabel = invoiceSignature?.label;
        _formData.append("files", image);
        filesData.push({
          fileIndex: filesData.length,
          module: "quotations",
          moduleFileType: "signature",
          level: "HEADER_LEVEL",
          action: "Create",
        });
      }

      _formData.append(
        "supplierHeader",
        JSON.stringify(supplierData.supplierHeader)
      );
      _formData.append(
        "supplierLines",
        JSON.stringify(supplierData.supplierLines)
      );
      _formData.append(
        "requiredDocs",
        JSON.stringify(OriginalData?.quotationInfo?.requiredDocs)
      );
      _formData.append("docKeys", JSON.stringify(filesData));
      _formData.append("supplierApprovals", JSON.stringify(supplierApprovals));
      // _formData.append("files", JSON.stringify([]));
      // console.log("_formData", _formData);
      // return
      const response = await quotationApis.submitSupplierQuote(_formData);
      const { status, message, data } = response;
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Quoted Successfully",
        });
        closeDrawer();
        navigate("/view-rfqs");
        // setIsLoading(true);
        // fetchOrderDetails();
        // setSelectedDeliveryAddress(null);
        // setSelectedNeedByDate(null);
        // setApprovalRequired("false");
        // setApprovals({
        //   open: false,
        //   data: [],
        // });
        // setInvoiceSignature((prev: any) => ({
        //   ...prev,
        //   isActive: false,
        //   image: null,
        //   label: "Authorised Signatory",
        // }));
        // setTermsAndConditions("");
        // onSelectChange([]);
        // setInvoiceableLines([]);
        // form.resetFields();
        // window.location.reload();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message,
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false);
    setDisableCreateQuoteSubmit(false);
  };

  const regretSupplier = async () => {
    console.log("regretSupplier", OriginalData);
    try {
      const res = await quotationApis.regretSupplier(
        OriginalData?.supplierHeader?.quotationId,
        OriginalData?.supplierHeader?.referencedSupplier?.referencedSupplierId
      );
      const { status, message, response }: any = res;
      if (status === true) {
        Swal.fire("Regretted!", message, "success");
        navigate("/quoted_view");
      } else {
        Swal.fire("Error!", response, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableRegret(false);
  };

  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  return isLoading == true ? (
    <>
      <Flex align="center" gap="middle" className="pageLoader">
        <Spin size="large" />
      </Flex>
    </>
  ) : (
    <>
      <Head title="View Quotation Details" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        RFQ -{" "}
                        {OriginalData?.quotationInfo?.quotationHeader?.rfqId}
                        {/* |{" "} */}
                        {/* {OriginalData?.quotationInfo?.quotationHeader?.status}{" "} */}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>

                  <div className="d-flex">
                    <div className="actions clearfix">
                      <ul
                        className="m-auto d-flex align-items-center justify-content-center p-0"
                        style={{
                          flexDirection: "row-reverse",
                        }}
                      >
                        <li className="pt-0 pb-0">
                          {disableRegret ? (
                            <Button color="primary" type="button">
                              <Spinner size="sm" color="light" />
                            </Button>
                          ) : (
                            <Button
                              color="secondary"
                              onClick={() => {
                                setDisableRegret(true);
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "Do you want to Regretted?",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, Regret!",
                                  cancelButtonText: "No, cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    regretSupplier();
                                    // Swal.fire(
                                    //     "Regretted!",
                                    //     "Your changes have been reverted.",
                                    //     "success"
                                    // );
                                  }
                                });
                              }}
                              type="button"
                              className="btn-dim btn btn-secondary"
                            >
                              Regret
                            </Button>
                          )}
                        </li>
                        <li className="pt-0 pb-0">
                          <Button
                            className="toggle"
                            color="primary"
                            onClick={async () => {
                              navigate("/view-rfqs");
                            }}
                          >
                            <Icon name="arrow-left" />
                            <span>Back</span>
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div className="content clearfix">
                <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                  <Panel header="General Information" key="1">
                    <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0 position-relative">
                      <li className="nav-item" onClick={() => setTab("1")}>
                        <a
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "1" ? " active" : ""}`}
                          href="#year"
                        >
                          General Info
                        </a>
                      </li>
                      <li className="nav-item" onClick={() => setTab("2")}>
                        <a
                          href="#overview"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "2" ? " active" : ""}`}
                        >
                          Delivery Info
                        </a>
                      </li>
                      {tab == "2" &&
                        OriginalData?.quotationInfo?.quotationHeader
                          ?.needByDateType == "order Level" && (
                          <span
                            className="cursor-pointer position-absolute"
                            style={{
                              right: 20,
                              top: 10,
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (tab === "2") {
                                setValue(
                                  "inwardRequired",
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.inwardRequired
                                );
                                setValue(
                                  "needByDateType",
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.needByDateType
                                );
                                setValue(
                                  "needByDate",
                                  dayjs(
                                    OriginalData?.quotationInfo?.quotationHeader
                                      ?.needByDate
                                  )
                                );
                                setValue(
                                  "billingAddress",
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.deliveryAddress?.deliveryLocationName
                                );
                                setValue(
                                  "deliveryAddressType",
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.deliveryAddressType
                                );
                                setValue(
                                  "deliveryAddress",
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.deliveryAddress?.deliveryLocationName
                                );
                              }
                              setGeneralInformationEdit(true);
                            }}
                          >
                            <Icon name="edit" />
                          </span>
                        )}
                    </ul>

                    <div className="tab-content mt-0">
                      {tab === "1" && (
                        <div className={`tab-pane${tab === "1" && " active"}`}>
                          <div style={{ margin: "1%" }}>
                            <Descriptions
                              title=""
                              className="custom-description-title"
                            >
                              <Descriptions.Item label="Customer">
                                {
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.orgName
                                }
                              </Descriptions.Item>
                              <Descriptions.Item label="Site">
                                {
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.siteName
                                }
                              </Descriptions.Item>
                              <Descriptions.Item label="Description">
                                {OriginalData?.quotationInfo?.quotationHeader
                                  ?.quotationDescription || "NA"}
                              </Descriptions.Item>
                              <Descriptions.Item label="Last Submission Date">
                                <span className="badge-dim badge bg-primary rounded-pill">
                                  <strong>
                                    {" "}
                                    {OriginalData?.quotationInfo
                                      ?.quotationHeader?.lastSubmissionDate &&
                                      dayjs(
                                        OriginalData?.quotationInfo
                                          ?.quotationHeader?.lastSubmissionDate
                                      ).format("DD-MMM-YYYY")}
                                  </strong>
                                </span>
                              </Descriptions.Item>
                              <Descriptions.Item label="Submission Type">
                                {
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.deadlineType
                                }
                              </Descriptions.Item>
                              <Descriptions.Item label="Additional Charges">
                                {
                                  OriginalData?.quotationInfo?.quotationHeader
                                    ?.additionalCharges
                                }
                              </Descriptions.Item>
                            </Descriptions>
                          </div>
                        </div>
                      )}
                      {tab === "2" && (
                        <div className={`tab-pane${tab === "2" && " active"}`}>
                          <Col className="justify-start w-100  flex-wrap mt-3 p-0">
                            <div className="w-40  paddingLeft10 border-end">
                              <div className=" pt-1">
                                <div className="data-label w-90 mb-1">
                                  Billing Address
                                </div>
                                <div className="data-value w-90">
                                  <ShowBillingAddress
                                    address={
                                      OriginalData?.quotationInfo
                                        ?.quotationHeader?.billingAddressName
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {OriginalData?.quotationInfo?.quotationHeader
                              ?.deliveryAddressId && (
                              <div className="w-40 paddingLeft10 ">
                                <div className=" pt-1">
                                  <div className="data-label w-9 mb-1">
                                    Delivery Address
                                  </div>
                                  <div className="data-value w-90">
                                    <ShowDeliveryAddress
                                      address={
                                        OriginalData?.quotationInfo
                                          ?.quotationHeader?.deliveryAddressName
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {OriginalData?.quotationInfo?.quotationHeader
                              ?.deliveryDateType === "ORDER_LEVEL" && (
                              <div className="w-20 paddingLeft10 ">
                                <div className=" pt-1">
                                  <div className="data-label w-9 mb-1">
                                    Need By Data
                                  </div>
                                  <div className="data-value w-90">
                                    {OriginalData?.quotationInfo
                                      ?.quotationHeader?.deliveryDate &&
                                      dayjs(
                                        OriginalData?.quotationInfo
                                          ?.quotationHeader?.deliveryDate
                                      ).format("DD-MMM-YYYY")}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Col>
                        </div>
                      )}
                    </div>
                  </Panel>
                  <Panel header="Material Informations" key="2">
                    <Form form={form} component={false}>
                      <div className="d-flex justify-content-between m-0 p-0">
                        <div className="w-70 d-flex align-items-start"></div>
                        <Button
                          color="primary"
                          className="m-0"
                          style={{ height: 40 }}
                          onClick={async () => {
                            try {
                              // Trigger form validation before proceeding
                              await form.validateFields(); // Validate fields registered in the form

                              if (areAllRowsFilled()) {
                                showDrawer(); // Proceed with the preview if validation passes
                              } else {
                                message.error(
                                  "Please fill out all required fields."
                                );
                              }
                            } catch (errorInfo) {
                              // Handle form validation failure
                              message.error(
                                "Validation failed: Please fill out all required fields."
                              );
                              console.log("Validation failed:", errorInfo);
                            }
                          }}
                          disabled={selectedRowKeys.length === 0}
                        >
                          Preview
                        </Button>
                      </div>
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width: screenSize - 380 + "px",
                            margin: "0px auto",
                          }}
                        >
                          <Table
                            className="customTable"
                            columns={mergedColumns}
                            rowSelection={rowSelection}
                            components={{
                              body: {
                                cell: EditableCell,
                              },
                            }}
                            dataSource={filterData.map((item: any) => ({
                              ...item,
                              key: item?.quotationLineId,
                            }))}
                            scroll={{ x: 1000 }}
                            expandable={{
                              expandedRowRender: (record: any) => (
                                <div className="expandableBody">
                                  <div style={{ flex: 1, textAlign: "left" }}>
                                    <p style={{ margin: 0 }}>
                                      {record.description}
                                    </p>
                                    {record.file && (
                                      <div>
                                        <PaperClipOutlined /> &nbsp;
                                        <a
                                          href={record?.file?.fileName}
                                          download={record?.file?.fileName}
                                        >
                                          {record?.file?.fileName}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                  {OriginalData?.quotationInfo?.quotationHeader
                                    ?.deliveryDateType === "LINE_LEVEL" &&
                                    record.deliveryAddressName && (
                                      <div
                                        className="lineAddress"
                                        style={{ flex: 1 }}
                                      >
                                        <h6>Delivery Address</h6>
                                        <ShowAddressPopOver
                                          address={record.deliveryAddressName}
                                        />
                                      </div>
                                    )}
                                </div>
                              ),
                            }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </div>
                        {/* {filterData?.length > 0 && (
                          <ViewTableTotals
                            data={filterData}
                            additionalCharges={[]}
                            // invoice={invoice} // Make sure to pass other necessary props
                          />
                        )} */}
                        <Row className="m-4 justify-content-end ">
                          {signature?.isActive && (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                              </div>
                              <div
                                style={{
                                  minHeight: signature?.image ? "0px" : "120px",
                                  display: signature?.image ? "block" : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {signature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={signature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>

                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {
                                            setInvoiceSignature((pre: any) => {
                                              return {
                                                ...pre,
                                                label: e.target.value,
                                              };
                                            });
                                          }}
                                          defaultValue={
                                            signature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Form>
                  </Panel>
                  <Panel header="Other Information" key="3">
                    <Descriptions title="" className="custom-description-title">
                      <Descriptions.Item label="Instructions">
                        <span
                          style={{
                            color: "#2263b3",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setInstructionsVisible(true)}
                        >
                          View Instructions
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Terms and Conditions">
                        <span
                          style={{
                            color: "#2263b3",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setTermsVisible(true)}
                        >
                          View Terms and Conditions
                        </span>
                      </Descriptions.Item>
                      {attachments?.length > 0 && (
                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>{index + 1}.</strong>
                                      &nbsp;
                                      {attachment.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Panel>
                </AntCollapse>

                <Drawer
                  title={
                    <div className="d-flex justify-content-between align-items-center">
                      <strong>Create Quotation</strong>
                      <div style={{ textAlign: "right" }}>
                        {disableCreateQuoteSubmit ? (
                          <Button color="primary" type="button">
                            <Spinner size="sm" color="light" />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              form2.submit();
                            }}
                            color="primary"
                            className="m-3 mt-0 mb-0"
                          >
                            Create Quotation
                          </Button>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                  onClose={closeDrawer}
                  open={drawerVisible}
                  width="85vw"
                  style={{
                    position: "absolute",
                    zIndex: 1100,
                  }}
                  styles={{ body: { paddingBottom: 80 } }}
                >
                  <Form
                    initialValues={{
                      approvals: "N", // Set the default value here
                    }}
                    form={form2}
                    layout="vertical"
                    onFinish={(data) => {
                      if (!invoiceSignature?.image) {
                        Swal.fire({
                          icon: "info",
                          title: "Signature",
                          text: "Signature is required",
                        });
                        return;
                      }
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You are about to create!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setDisableSubmit(true);
                          setDisableCreateQuoteSubmit(true);
                          createInvoice(data);
                        }
                      });
                    }}
                    requiredMark={false}
                  >
                    <AntCollapse defaultActiveKey={["1", "2"]} className="mt-3">
                      <Panel
                        header={<h6>Billing & Other Information</h6>}
                        key="1"
                      >
                        <div className="d-flex justify-content-around ">
                          <div className="w-50">
                            <div className="justify-start w-100  flex-wrap pt-0">
                              <div className={` w-50  paddingLeft10`}>
                                <div className=" pt-1">
                                  <div className="data-label w-90 mb-1">
                                    Billing Address
                                  </div>
                                  <div className="data-value w-90">
                                    <ShowBillingAddress
                                      address={
                                        OriginalData?.quotationInfo
                                          ?.quotationHeader?.billingAddressName
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              {OriginalData?.quotationInfo?.quotationHeader
                                ?.deliveryAddressId && (
                                <div className="w-40 paddingLeft10 ">
                                  <div className=" pt-1">
                                    <div className="data-label w-9 mb-1">
                                      Delivery Address
                                    </div>
                                    <div className="data-value w-90">
                                      <ShowDeliveryAddress
                                        address={
                                          OriginalData?.quotationInfo
                                            ?.quotationHeader
                                            ?.deliveryAddressName
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="border-end"></div>
                          <div className="w-45">
                            <div className="justify-start w-100  flex-wrap pt-3">
                              <Form.Item
                                name="negotiable"
                                label={
                                  <div className="data-label w-90 mb-1">
                                    Negotiable
                                  </div>
                                }
                                className="w-50 p-3 paddingLeft10  pt-0 pb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select type",
                                  },
                                ]}
                              >
                                <Select placeholder="Select">
                                  {/* <Option value="dc">Delivery Challan</Option> */}
                                  <Option value="Y">Yes</Option>
                                  <Option value="N">No</Option>
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name="approvals"
                                label={
                                  <div className="data-label w-100 mb-1">
                                    Approval Required ?
                                  </div>
                                }
                                className="w-50 p-3 paddingLeft10 pt-0 pb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select approvals",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Approvals"
                                  value={approvalRequired}
                                  onChange={(e) => {
                                    form2.setFieldValue("approvals", e);
                                    setApprovalRequired(() => e);
                                  }}
                                  defaultValue="N"
                                >
                                  <Option value="Y">Yes</Option>
                                  <Option value="N">No</Option>
                                </Select>
                                {approvalRequired == "Y" && (
                                  <Popover
                                    content="Click here to set required approvals"
                                    title="Approval Required"
                                  >
                                    <a
                                      href="#"
                                      className="success"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setApprovals((pre) => {
                                          return {
                                            ...pre,
                                            open: true,
                                          };
                                        });
                                      }}
                                    >
                                      <EditOutlined /> Set Approvals &nbsp;
                                      {approvals?.data?.length == 0 ? (
                                        <span className="mandatory">
                                          Please set approvals
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </a>
                                  </Popover>
                                )}
                              </Form.Item>

                              <Form.Item
                                name="invoiceDate"
                                label={
                                  <div className="data-label w-90 mb-1">
                                    Validity Date
                                  </div>
                                }
                                className="w-50 p-3 paddingLeft10  pt-0 pb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Validity Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  placeholder="Validity Date"
                                  minDate={dayjs().add(0, "day")}
                                  onChange={(e) => {
                                    setInvoiceDate(e);
                                  }}
                                />
                              </Form.Item>

                              <Form.Item
                                name="paymentTerms"
                                label={
                                  <div className="data-label w-100 mb-1">
                                    Payment Terms
                                  </div>
                                }
                                className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Payment Terms",
                                  },
                                ]}
                              >
                                <Select
                                  options={[
                                    {
                                      label: "Cash & Carry",
                                      value: "Cash and Carry",
                                    },
                                    // { label: "Advance", value: "Advance" },
                                    { label: "Credit", value: "Credit" },
                                  ]}
                                  placeholder="Select Payment Terms"
                                  onChange={handlePaymentTermChange}
                                />
                              </Form.Item>

                              {paymentTerm === "Advance" && (
                                <>
                                  <Form.Item
                                    name="advancePercentage"
                                    label={
                                      <div className="data-label w-100 mb-1">
                                        Advance Percentage
                                      </div>
                                    }
                                    className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select Advance Percentage",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="w-100"
                                      type="number"
                                      placeholder="Enter Advance Percentage"
                                      // pattern={/^(0|[1-9][0-9]?|100)$/}
                                    />
                                  </Form.Item>
                                </>
                              )}
                              {paymentTerm === "Credit" && (
                                <Form.Item
                                  name="paymentDays"
                                  label={
                                    <div className="data-label w-100 mb-1">
                                      Credit Days
                                    </div>
                                  }
                                  className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select Credit Days",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="w-100"
                                    type="number"
                                    placeholder="Enter Credit Days"
                                  />
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        </div>
                      </Panel>

                      <Panel header={<h6>Material Information</h6>} key="2">
                        <div className="table-responsive poTable">
                          <div
                            style={{
                              width: screenSize - 350 + "px",
                              margin: "0px auto 15px auto",
                            }}
                          >
                            <Table
                              className="customTable"
                              columns={previewColumns}
                              dataSource={invoiceableLines}
                              scroll={{ x: 1000 }}
                              expandable={{
                                expandedRowRender: (record: any) => (
                                  <div className="expandableBody">
                                    <div style={{ flex: 1, textAlign: "left" }}>
                                      <p style={{ margin: 0 }}>
                                        {record.description}
                                      </p>
                                      {record.file && (
                                        <div>
                                          <PaperClipOutlined /> &nbsp;
                                          <a
                                            href={record?.file?.fileName}
                                            download={record?.file?.fileName}
                                          >
                                            {record?.file?.fileName}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                    {/* {materialData?.deliveryAddressType ===
                                      "LINE_LEVEL" &&
                                      record.deliveryAddressId && (
                                        <div
                                          className="lineAddress"
                                          style={{ flex: 1 }}
                                        >
                                          <h6>Delivery Address</h6>
                                          <ShowAddressPopOver
                                            address={record.deliveryAddress}
                                          />
                                        </div>
                                      )} */}
                                  </div>
                                ),
                              }}
                              bordered
                              size="middle"
                              pagination={false}
                            />
                          </div>
                        </div>

                        <div className="d-flex">
                          <Row className="mt-2 w-50">
                            <div>
                              {OriginalData?.quotationInfo?.quotationHeader
                                ?.additionalCharges !== "Fixed" && (
                                <div className="d-flex align-items-center">
                                  <h6 className="m-1 mb-0">
                                    Additional Charges (If Any)
                                  </h6>
                                  <div className="w-20 m-1 mb-0">
                                    {editAdditionalCharges ? (
                                      <a
                                        type={undefined}
                                        onClick={() => {
                                          if (
                                            additionalCharges.find(
                                              (field: any, ind: number) => {
                                                const name =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${field?.id}.name`
                                                  );
                                                const price =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${field?.id}.price`
                                                  );
                                                return !name || !price;
                                              }
                                            )
                                          ) {
                                            return;
                                          }

                                          setAdditionalCharges([
                                            ...additionalCharges,
                                            {
                                              name: "",
                                              price: null,
                                              id: nanoid(4),
                                            },
                                          ]);
                                        }}
                                        className="addNewItem"
                                      >
                                        <PlusSquareOutlined />
                                      </a>
                                    ) : (
                                      <a
                                        type={undefined}
                                        onClick={() => {
                                          setEditAdditionalCharges(true);
                                        }}
                                        className="addNewItem"
                                      >
                                        <EditOutlined />
                                      </a>
                                    )}
                                  </div>
                                </div>
                              )}
                              {additionalCharges.map(
                                (field: any, index: number) => (
                                  <Row
                                    key={field.id}
                                    className="m-2 mb-0 w-100"
                                  >
                                    <div className="w-50">
                                      {editAdditionalCharges ? (
                                        <Form.Item
                                          name={`AdditionalCharges.${field.id}.name`}
                                          label={
                                            <strong>
                                              Additional Charge Name
                                            </strong>
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Charge Name is required",
                                            },
                                          ]}
                                          className="mb-0"
                                        >
                                          <Input
                                            className="w-100"
                                            disabled={!editAdditionalCharges}
                                            autoComplete="off"
                                          />
                                        </Form.Item>
                                      ) : (
                                        <strong>{field.name}</strong>
                                      )}
                                    </div>
                                    <div className="w-30">
                                      {editAdditionalCharges ? (
                                        <Form.Item
                                          name={`AdditionalCharges.${field.id}.price`}
                                          label={<strong>Price (INR)</strong>}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Price is required",
                                            },
                                          ]}
                                          className="mb-0"
                                        >
                                          <InputNumber
                                            min={0}
                                            step={0.01}
                                            disabled={!editAdditionalCharges}
                                            className="w-100"
                                            formatter={
                                              (value) =>
                                                `${value}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) // Adds commas for thousands
                                            }
                                            parser={
                                              (value: any) =>
                                                value!.replace(
                                                  /\$\s?|(,*)/g,
                                                  ""
                                                ) // Removes commas and symbols
                                            }
                                            onKeyPress={(event) => {
                                              if (!/^[0-9.]$/.test(event.key)) {
                                                event.preventDefault(); // Prevent non-numeric and non-decimal input
                                              }
                                            }}
                                            autoComplete="off"
                                          />
                                        </Form.Item>
                                      ) : (
                                        <strong>
                                          {FormatCurrency(
                                            field.price,
                                            currency?.currency_format,
                                            currency?.currency_symbol
                                          )}
                                        </strong>
                                      )}
                                    </div>
                                    <Col className="d-flex align-items-end w-15">
                                      {editAdditionalCharges && (
                                        <a
                                          href="#dropdownitem"
                                          onClick={() => {
                                            form2.setFieldsValue({
                                              [`AdditionalCharges.${field.id}.name`]:
                                                "",
                                              [`AdditionalCharges.${field.id}.price`]:
                                                null,
                                            });
                                            const newAdditionalCharges: any =
                                              [];
                                            additionalCharges.forEach(
                                              (fld: any, ind: number) => {
                                                if (fld.id != field.id) {
                                                  const name =
                                                    form2.getFieldValue(
                                                      `AdditionalCharges.${fld.id}.name`
                                                    );
                                                  const price =
                                                    form2.getFieldValue(
                                                      `AdditionalCharges.${fld.id}.price`
                                                    );
                                                  if (name && price) {
                                                    newAdditionalCharges.push({
                                                      name,
                                                      price,
                                                      id: fld.id,
                                                    });
                                                  }
                                                }
                                              }
                                            );
                                            setAdditionalCharges(
                                              newAdditionalCharges
                                            );
                                          }}
                                          className="addNewItem"
                                        >
                                          <DeleteOutlined />
                                        </a>
                                      )}
                                    </Col>
                                  </Row>
                                )
                              )}
                              {additionalCharges.length > 0 &&
                                editAdditionalCharges && (
                                  <Button
                                    className="m-4 mt-3 mb-0"
                                    color="primary"
                                    onClick={() => {
                                      const newAdditionalCharges: any = [];
                                      additionalCharges.forEach(
                                        (field: any, index: number) => {
                                          const name = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.name`
                                          );
                                          const price = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.price`
                                          );
                                          if (name && price) {
                                            newAdditionalCharges.push({
                                              name,
                                              price,
                                              id: field?.id,
                                            });
                                          }
                                        }
                                      );
                                      setAdditionalCharges(() => {
                                        return newAdditionalCharges;
                                      });
                                      if (newAdditionalCharges.length != 0) {
                                        setEditAdditionalCharges(false);
                                      }
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                            </div>
                          </Row>

                          <ViewTableTotals
                            data={invoiceableLines}
                            additionalCharges={additionalCharges}
                            invoice={true}
                          />
                        </div>
                        <Row className="m-4 justify-content-end ">
                          {invoiceSignature?.isActive ? (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                                <a
                                  style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setInvoiceSignature((prev: any) => ({
                                      ...prev,
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    }));
                                    setValue("signatureObj", {
                                      isActive: false,
                                      image: null,
                                      label: "Authorised Signatory",
                                    });
                                  }}
                                >
                                  <Icon
                                    name="cross"
                                    className="pointer"
                                    onClick={() => {
                                      setInvoiceSignature((prev: any) => ({
                                        ...prev,
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      }));
                                      setValue("signatureObj", {
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      });
                                    }}
                                  />
                                </a>
                              </div>
                              <div
                                style={{
                                  minHeight: invoiceSignature?.image
                                    ? "0px"
                                    : "120px",
                                  display: invoiceSignature?.image
                                    ? "block"
                                    : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {!invoiceSignature?.image && (
                                  <Button
                                    color="btn-dim btn"
                                    onClick={() => {
                                      setShowCropModel(true);
                                    }}
                                  >
                                    <Icon
                                      name="upload-cloud"
                                      className="pointer"
                                      onClick={() => {
                                        setShowCropModel(true);
                                      }}
                                    />
                                    <span>Upload</span>
                                  </Button>
                                )}
                                {invoiceSignature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={invoiceSignature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        color="btn-dim btn"
                                        onClick={() => {
                                          setInvoiceSignature((pre: any) => {
                                            return { ...pre, image: null };
                                          });
                                        }}
                                      >
                                        <span>Reset</span>
                                        <Icon
                                          name="redo"
                                          className="pointer"
                                          onClick={() => {
                                            setInvoiceSignature((pre: any) => {
                                              return { ...pre, image: null };
                                            });
                                          }}
                                        />
                                      </Button>
                                    </div>
                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {
                                            setInvoiceSignature((pre: any) => {
                                              return {
                                                ...pre,
                                                label: e.target.value,
                                              };
                                            });
                                          }}
                                          defaultValue={
                                            invoiceSignature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              {!invoiceSignature?.image && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Button
                                      color="btn-dim btn"
                                      onClick={() =>
                                        setShowSignatureModel(true)
                                      }
                                    >
                                      <Icon name="pen" />
                                      <span>Use Signature Pad</span>
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid #e5ecf7",
                                backgroundColor: "#f7faff",
                                padding: "10px",
                                marginLeft: "10px",
                                width: "22%",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              <Button
                                color="btn-dim btn"
                                onClick={() => {
                                  setInvoiceSignature((pre: any) => {
                                    return { ...pre, isActive: true };
                                  });
                                }}
                              >
                                <Icon
                                  name="edit"
                                  className="pointer"
                                  onClick={() => {
                                    setInvoiceSignature((pre: any) => {
                                      return { ...pre, isActive: true };
                                    });
                                  }}
                                />
                                <span>Add Signature</span>
                              </Button>
                            </div>
                          )}
                        </Row>
                      </Panel>
                      <Panel header={<h6>Terms and Conditions</h6>} key="3">
                        <div>
                          <ReactQuill
                            theme="snow"
                            value={supplierTermsAndConditions}
                            onChange={(data) => {
                              setsupplierTermsAndConditions(data);
                            }}
                            className="mb-3"
                          />
                        </div>
                      </Panel>
                      <Panel header={<h6>Attachments (If Any)</h6>} key="4">
                        <div className="w-100 d-flex m-2">
                          <div className="w-25">
                            <Form.Item
                              name="attachDC"
                              label={<strong>Attach Documents</strong>}
                              valuePropName="fileList"
                              getValueFromEvent={(e: any) => e?.fileList}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please upload at least one document.",
                                },
                              ]}
                            >
                              <Upload
                                beforeUpload={beforeUpload}
                                onChange={handleChangeAttachment}
                                multiple={true}
                                fileList={documentsAttached}
                                accept=".pdf,.jpg,.jpeg,.png"
                                className="w-100"
                              >
                                <AntButton icon={<UploadOutlined />}>
                                  Click to Upload
                                </AntButton>
                              </Upload>
                            </Form.Item>
                          </div>
                          {/* Debugging Output */}
                          {/* {JSON.stringify(requiredDOC)} */}

                          {/* Dynamic Form Items */}
                          {requiredDOC &&
                            requiredDOC.length > 0 &&
                            requiredDOC.map((doc: any) => (
                              <div className="w-25">
                                <Form.Item
                                  key={doc.quotationDocNameId}
                                  name={`doc_${doc.quotationDocNameId}`}
                                  label={<strong>{doc.documentName}</strong>}
                                  valuePropName="fileList"
                                  getValueFromEvent={(e: any) =>
                                    Array.isArray(e) ? e : e?.fileList
                                  }
                                  rules={[
                                    {
                                      required: doc.mandatory == 1,
                                      message: `${doc.documentName} is required`,
                                    },
                                  ]}
                                >
                                  <Upload {...uploadProps} className="w-100">
                                    <AntButton icon={<UploadOutlined />}>
                                      Click to Upload
                                    </AntButton>
                                  </Upload>
                                </Form.Item>
                              </div>
                            ))}
                          {/* </div> */}
                        </div>
                      </Panel>
                    </AntCollapse>
                    {showCropModel && (
                      <CropperComponent
                        modal={showCropModel}
                        toggle={() => setShowCropModel(!showCropModel)}
                        onSave={(data: string) => {
                          setInvoiceSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowCropModel(false);
                        }}
                      />
                    )}
                    {showSignatureModel && (
                      <SignatureModal
                        modal={showSignatureModel}
                        toggle={() =>
                          setShowSignatureModel(!showSignatureModel)
                        }
                        onSave={(data: string) => {
                          setInvoiceSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowSignatureModel(false);
                        }}
                      />
                    )}
                  </Form>
                </Drawer>

                <AntModal
                  title="Instructions"
                  open={instructionsVisible}
                  footer={null}
                  onCancel={() => setInstructionsVisible(false)}
                  width="60%"
                  styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: instructions || "",
                    }}
                  />
                </AntModal>
                <AntModal
                  title="Terms and Conditions"
                  open={termsVisible}
                  footer={null}
                  onCancel={() => setTermsVisible(false)}
                  width="60%"
                  styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: termsAndConditions || "",
                    }}
                  />
                </AntModal>
              </div>
            </div>
          </div>
        </Card>
      </Content>

      {approvals?.open && (
        <QuotationApprovalDrawer
          open={approvals.open}
          onClose={() => {
            setApprovals((pre) => {
              return {
                ...pre,
                open: false,
              };
            });
          }}
          onSubmit={(data: any) => {
            setApprovals({
              open: false,
              data: data,
            });
          }}
          stateIds={prefferedStates}
          setApprovalSubmissionStatus={(status: boolean) => {}}
          approvalData={approvals.data} // Pass the current approval data
        />
      )}
    </>
  );
};

const fetchHtmlContentFromUrl = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "text/html",
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const ShowAddressPopOver = ({ address }: any) => {
  return (
    <ul>
      <li>{address.addressLine1}</li>
      <li>{address.addressLine2}</li>
      <li>
        {address.city}, {address.stateName}, {address.countryName} -{" "}
        {address.pincode}
      </li>
    </ul>
  );
};

export default RFQViewDetails;
